import { Utils } from '../utils/utils'
import axios from 'axios'

export class TiposIdentificacionHelper {

    utils: Utils = new Utils();
    

    getTiposIdentificacion = async () => {        
        const urlTiposIdentificacion = this.utils.getTodosTiposIdentificacion();
        return axios.get(`${urlTiposIdentificacion}`)
    }

}


