import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { TiposIdentificacionHelper } from '@/helpers/tipo-documento.helper';
import { TipoIdentificacion } from '@/models/tipo-identificacion.model';




const tiposIdentificacionHelper: TiposIdentificacionHelper = new TiposIdentificacionHelper();



class State {    
    tiposIdentificacion: TipoIdentificacion[] = [];
    consultandoTiposIdentificacion: boolean = false;
    error: boolean = false

}

const getters = <GetterTree<State, any>>{

    getterTiposIdentificacion(state) {
        return state.tiposIdentificacion
    },

    getterConsultandoTiposIdentificacion(state) {

        return state.consultandoTiposIdentificacion
    }
};

const mutations = <MutationTree<State>>{


    guardarTiposIdentificacion(state, tiposIdentificacion: TipoIdentificacion[]) {
        state.tiposIdentificacion = tiposIdentificacion
    },

    consultandoTiposIdentificacion(state, value) {
        state.consultandoTiposIdentificacion = value
    },

    errorConsultaTiposIdentificacion(state, value) {
        state.error = value
    },

};

const actions = <ActionTree<State, any>>{

    async consultarTiposIdentificacion(state) {


        state.commit('consultandoTiposIdentificacion', true);
        const resp = await tiposIdentificacionHelper.getTiposIdentificacion();
        if (resp) {
            state.commit('guardarTiposIdentificacion', resp.data);
        } else {
            state.commit('errorConsultaTiposIdentificacion', true);
        }
        state.commit('consultandoTiposIdentificacion', false);


    }
};


const TiposIdentificacionModule = {
    namespaced: true,
    getters,
    state: new State(),
    mutations,
    actions
};

export default TiposIdentificacionModule;

