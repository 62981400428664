import { Utils } from '../utils/utils'
import axios from 'axios'
import * as md5 from 'md5';
import { DisponibilidadRequest } from '@/models/disponibilidad-request.model';


export class DisponibilidadlHelper {

    utils:Utils = new Utils();

    async getDisponibilidad(disponibilidadRequest:DisponibilidadRequest) {
        const time = new Date().getTime();
        const hash = md5.default(JSON.stringify(disponibilidadRequest) + time + 'disponibilidad-est');
        const urlDisponibilidad = this.utils.getDisponibilidadEstablecimiento()
        return axios.post(`${urlDisponibilidad}/${hash}/${time}`, disponibilidadRequest)
    }

}

