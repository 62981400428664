import { Utils } from '../utils/utils'
import axios from 'axios'
import * as md5 from 'md5';
import { Establecimiento } from '../models/establecimiento.model';

export class EstablecimientoHelper {

    utils: Utils = new Utils();

    getEstablecimiento = async (idEstablecimiento: String) => {

        const urlEstablecimientoPorId = this.utils.getUrlEstablacimiento();
        return axios.get<Establecimiento>(`${urlEstablecimientoPorId}/${idEstablecimiento}`)
    }

    getAforosPorEstablecimiento = async (idEstablecimiento: String) => {        
        const time = new Date().getTime();
        const hash:any = md5.default(JSON.stringify(idEstablecimiento) + time + 'af');
        const urlAforosPorEstablecimiento = this.utils.getUrlAforosPorEstablecimiento();
        return axios.get(`${urlAforosPorEstablecimiento}/${idEstablecimiento}/${hash}/${time}`)
    }

}


