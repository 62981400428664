<template>  
  <router-view/>
</template>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/sass/plugins";
@import "assets/sass/style";
</style>
