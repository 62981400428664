import { Establecimiento } from '@/models/establecimiento.model';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { EstablecimientoHelper } from '../../helpers/establecimiento.helper'

const establecimientoHelper: EstablecimientoHelper = new EstablecimientoHelper();



class State {
    idestablecimiento: string = '';
    establecimiento: Establecimiento = {};
    consultandoEstablecimiento: boolean = false;
    error: boolean = false

}

const getters = <GetterTree<State, any>>{

    getterEstablecimiento(state) {
        return state.establecimiento
    },

    getterConsultandoEstablecimiento(state) {

        return state.consultandoEstablecimiento
    }
};

const mutations = <MutationTree<State>>{

    guardarIdEstablecimiento(state, id) {
        state.idestablecimiento = id
    },

    guardarEstablecimiento(state, establecimiento) {
        state.establecimiento = establecimiento
    },

    consultandoEstablecimiento(state, value) {
        state.consultandoEstablecimiento = value
    },

    errorEstablecimiento(state, value) {
        state.error = value
    },

};

const actions = <ActionTree<State, any>>{

    async consultarEstablecimientoAction(state, idEstablecimiento) {


        state.commit('consultandoEstablecimiento', true);

        state.commit('guardarIdEstablecimiento', idEstablecimiento);
        const establecimiento = await establecimientoHelper.getEstablecimiento(idEstablecimiento);
        if (establecimiento) {
            state.commit('guardarEstablecimiento', establecimiento.data);
        } else {
            state.commit('errorEstablecimiento', true);
        }
        state.commit('consultandoEstablecimiento', false);


    }
};


const EstablecimientoModule = {
    namespaced: true,
    getters,
    state: new State(),
    mutations,
    actions
};

export default EstablecimientoModule;

