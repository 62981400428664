import { Utils } from '../utils/utils'
import axios from 'axios'
import * as md5 from 'md5';


export class SucursalHelper {

    utils:Utils = new Utils();

    async getSucursalesPorEstablecimiento(idEstablecimiento: string) {
        const time = new Date().getTime();
        const hash = md5.default(JSON.stringify(idEstablecimiento) + time + 'suc-est');
        const urlAforosPorEstablecimiento = this.utils.urlSucursal()
        return axios.get(`${urlAforosPorEstablecimiento}/establecimiento/${idEstablecimiento}/${hash}/${time}`)
    }

}

