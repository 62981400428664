

export class Utils {

    // puerto auth
    private apiPortAuth = '53520';
    private apiPortHttpsAuth = '53522';

    // puertos app
    private apiPort = '53521';
    private apiPortHttps = '53522';

    // auth
    private authToken = 'oauth/token';

    // tipo de documentos


    // genero
    private genero = 'autorizando/api/establecimiento/v1/genero';

    // genero
    private tipoDocumento = 'autorizando/api/establecimiento/v1/tipoidentificacion';


    // establecimiento
    private establecimiento = 'autorizando/api/establecimiento/v1/establecimiento';
    private establecimientoPorId = 'autorizando/api/establecimiento/v1/establecimiento/';
    private establecimientoDisponibilidad = 'autorizando/api/establecimiento/v1/disponibilidad';
    private aforosPorEstablecimiento = 'autorizando/api/establecimiento/v1/aforo/aforo-establecimiento';


    // establecimientos validacion token desde MRL
    private establecimientoToken = 'autorizando/api/app/v1/establecimiento/porusuario-token';

    //reserva
    private pathReserva = "autorizando/api/establecimiento/v1/reserva";

    // sucursales
    private pathSucursal = "autorizando/api/establecimiento/v1/sucursal";

    // visitas
    private pathVisitas = "autorizando/api/establecimiento/v1/visitas";


    // general
    private prefixApp = '';
    private prefixAuth = '';
    private protocol = window.location.protocol;
    private hostName:string = window.location.hostname;
    private separadorPuerto = ':';

    private secretKey = '$$KEO@@--vepbox-||JIN()Login%%';

    private webInicial = '';


    constructor() {

        this.apiPortAuth = '8765';
        this.apiPortHttpsAuth = '8765';

        this.apiPort = '8765';
        this.apiPortHttps = '8765';
      

        if (process.env.NODE_ENV === 'production') {

            this.prefixApp = '';
            this.prefixAuth = '';            
            //this.hostName = process.env.URL_MRLGATEWAY + '';
            this.hostName = 'mrlgateway.ideadual.com';
            this.separadorPuerto = '';
            this.apiPortAuth = '';
            this.apiPortHttpsAuth = '';
            this.apiPort = '';
            this.apiPortHttps = '';
        }
    }


    // modulo auth server

    public getAuthToken(): string {
        this.loadPortAuth();
        return this.protocol + '//' + this.hostName + this.separadorPuerto + this.apiPortAuth + '/' + this.prefixAuth + this.authToken;
    }


    // endpoints de aplicacion

    public getUrlEstablacimiento() {
        this.loadPort();
        return this.protocol + '//' +
            this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.establecimiento;
    }

    //reserva
    public urlReserva(): string {
        this.loadPort();
        return this.protocol + '//' +
            this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.pathReserva;
    }


    // sucursales
    public  urlSucursal(): string {
        this.loadPort();
        return this.protocol + '//' +
            this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.pathSucursal;
    }

    // establecimiento
    public getEstablecimientoToken(): string {
        this.loadPort();
        return this.protocol + '//' +
            this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.establecimientoToken;
    }

    public getEstablecimientoPorId(): string {
        this.loadPort();
        return this.protocol + '//' +
            this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.establecimientoPorId;
    }

    public getUrlAforosPorEstablecimiento(): string {
        this.loadPort();
        return this.protocol + '//' +
            this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.aforosPorEstablecimiento;
    }


    public getDisponibilidadEstablecimiento(): string {
        this.loadPort();
        return this.protocol + '//' +
            this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.establecimientoDisponibilidad;
    }


    public getGenero(): string {
        this.loadPort();
        return this.protocol + '//' + this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.genero;
    }

    public getTodosTiposIdentificacion(): string {
        this.loadPort();
        return this.protocol + '//' + this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.tipoDocumento;
    }


    // visistas
    public getVisitas(): string {
        this.loadPort();
        return this.protocol + '//' + this.hostName + this.separadorPuerto + this.apiPort + '/' + this.prefixApp + this.pathVisitas;
    }



    public statusReserva = {
        BLOQUEADA: 'BLOQUEADA',
        CREADA: 'CREADA',

    }


    // cargue de puertos
    loadPort(): any {
        if (this.protocol.indexOf('https') !== -1) {
            this.apiPort = this.apiPortHttps;
        }
    }

    loadPortAuth(): any {
        if (this.protocol.indexOf('https') !== -1) {
            this.apiPortAuth = this.apiPortHttpsAuth;
        }
    }


    /*utilidades de aplicacion */

    getSecretKey(): string {
        return this.secretKey;
    }


    getWebInicial() {

        if (process.env.NODE_ENV === 'production') {
            //this.webInicial = 'https://mireservaenlinea.com';
           // this.webInicial = process.env.URL_MRLPAGINAINICIAL + '';
           this.webInicial = 'https://mrl.ideadual.com';
        } else {
            this.webInicial = this.protocol + this.hostName + ':4200';
        }

        return this.webInicial;

    }

}

