import { ReservaHelper } from '@/helpers/reserva.helper';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Reserva } from '../../models/reserva.model';

const reservaHelperHelper: ReservaHelper = new ReservaHelper();



class State {
    reserva: Reserva = {}
    guardandoReserva: boolean = false
    actualizandoReserva: boolean = false
    error: boolean = false
    errorActualizar: boolean = false
    progresoReserva: number = 0


}

const getters = <GetterTree<State, any>>{

    getterReserva(state) {
        return state.reserva
    },

    getterGuardandoReserva(state) {

        return state.guardandoReserva
    },

    getterErrorGuardadoReserva(state) {

        return state.error
    },

    getterProgresoReserva(state) {

        return state.progresoReserva
    },
};

const mutations = <MutationTree<State>>{

    guardarReserva(state, reserva) {
        state.reserva = reserva
    },

    guardandoReserva(state, value) {
        state.guardandoReserva = value
    },

    actualizandoReserva(state, value) {
        state.actualizandoReserva = value
    },

    errorReserva(state, value) {
        state.error = value
    },

    errorActualizarReserva(state, value) {
        state.errorActualizar = value
    },

    actualizaProgresoReserva(state, value) {
       // state.progresoReserva = (value * 100 / 3)
       state.progresoReserva = value
    },


};

const actions = <ActionTree<State, any>>{

    async guardarReserva(state, reserva: Reserva) {


        state.commit('guardandoReserva', true);
        const reservaCreada = await reservaHelperHelper.crearReserva(reserva);
        if (reservaCreada) {
            state.commit('guardarReserva', reservaCreada.data);
            state.commit('actualizaProgresoReserva', 2);
        } else {
            state.commit('errorReserva', true);
        }
        state.commit('guardandoReserva', false);


    },

    async actualizarReserva(state, reserva: Reserva) {


        state.commit('actualizandoReserva', true);
        const resp = await reservaHelperHelper.actualizarReserva(reserva);
        if (resp) {
            state.commit('guardarReserva', reserva);
            state.commit('actualizaProgresoReserva', 3);
        } else {
            state.commit('errorActualizarReserva', true);
        }
        state.commit('actualizandoReserva', false);


    },


    actualizaProgresoreservaAction(state, value) {
        state.commit('actualizaProgresoReserva', value);
    }
};


const ReservaModule = {
    namespaced: true,
    getters,
    state: new State(),
    mutations,
    actions
};

export default ReservaModule;

