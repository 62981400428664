import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { GeneroHelper } from '@/helpers/genero.helper';
import { Genero } from '@/models/genero.model';



const generoHelper: GeneroHelper = new GeneroHelper();



class State {    
    generos: Genero[] = [];
    consultandoGeneros: boolean = false;
    error: boolean = false

}

const getters = <GetterTree<State, any>>{

    getterGeneros(state) {
        return state.generos
    },

    getterConsultandoGeneros(state) {

        return state.consultandoGeneros
    }
};

const mutations = <MutationTree<State>>{


    guardarGeneros(state, generos) {
        state.generos = generos
    },

    consultandoGeneros(state, value) {
        state.consultandoGeneros = value
    },

    errorConsultaGeneros(state, value) {
        state.error = value
    },

};

const actions = <ActionTree<State, any>>{

    async consultarGeneros(state) {


        state.commit('consultandoGeneros', true);
        const establecimiento = await generoHelper.getGeneros();
        if (establecimiento) {
            state.commit('guardarGeneros', establecimiento.data);
        } else {
            state.commit('errorConsultaGeneros', true);
        }
        state.commit('consultandoGeneros', false);


    }
};


const GenerosModule = {
    namespaced: true,
    getters,
    state: new State(),
    mutations,
    actions
};

export default GenerosModule;

