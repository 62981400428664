import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { SucursalHelper } from '../../helpers/sucursal.helper'

const sucursalHelper: SucursalHelper = new SucursalHelper();



class State {
    sucursales: any[] = [];
    consultandoSucursales: boolean = false;
    error: boolean = false;

}

const getters = <GetterTree<State, any>>{

    getterSucursales(state) {
        return state.sucursales
    },
    getterConsultandoSucursales(state) {
        return state.consultandoSucursales
    }
};

const mutations = <MutationTree<State>>{

    guardarSucursales(state, sucursales) {
        state.sucursales = sucursales
    },
    consultandoSucursales(state, value) {
        state.consultandoSucursales = value
    },
    errorSucursales(state, value) {
        state.error = value
    }

};

const actions = <ActionTree<State, any>>{

    async consultarSucursalesPorEstablecimientoAction (state, idEstablecimiento)  {


        state.commit('consultandoSucursales', true);
        const sucursales = await sucursalHelper.getSucursalesPorEstablecimiento(idEstablecimiento);
        if (sucursales) {
            state.commit('guardarSucursales', sucursales.data);
        } else {
            state.commit('errorSucursales', true);
        }
        state.commit('consultandoSucursales', false);
    }
};


const SucursalModule = {
    namespaced: true,
    getters,
    state: new State(),
    mutations,
    actions
};

export default SucursalModule;

