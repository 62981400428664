import { Utils } from '../utils/utils'
import axios from 'axios'
import { Reserva } from '../models/reserva.model';


export class ReservaHelper {

    utils:Utils = new Utils();

    async crearReserva(reserva: Reserva) {        
        return axios.post(`${this.utils.urlReserva()}`, reserva)
    }

    async actualizarReserva(reserva: Reserva) {        
        return axios.put(`${this.utils.urlReserva()}/${reserva.id}`, reserva)
    }

}

