import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes= [
  {
    path: '/404',
    name: '404',
    component: () =>
      import( /* webpackChunkName: "404" */ '../modules/compartido/Error404.vue')
  },
  {
    path: '/',
    name: 'Inicio',
    component: () =>
      import( /* webpackChunkName: "reservar" */ '../modules/reservar/ReservarLayout.vue'),
    children: [
    {
      path: 'res/reservas/establecimiento/:id',
      name: 'informacion-inicial',      
      props: (route:any) => {
        return !route.params.id ? { id: null } : { id: route.params.id }
      },
      component: () =>
        import( /* webpackChunkName: "informacion-inicial" */ '../modules/reservar/pages/InformacionInicial.vue'),

    },
    {
      path: 'res/reservas/disponibilidad/:id',
      name: 'disponibilidad',
      props: (route:any) => {
        return !route.params.id ? { id: null } : { id: route.params.id }
      },
      component: () =>
        import( /* webpackChunkName: "disponibilidad" */ '../modules/reservar/pages/Disponibilidad.vue'),
    },

    {
      path: 'res/reservas/informacion-reserva/:id',
      name: 'informacion-reserva',
      props: (route:any) => {
        return !route.params.id ? { id: null } : { id: route.params.id }
      },
      component: () =>
        import( /* webpackChunkName: "informacion-reserva" */ '../modules/reservar/pages/InformacionReserva.vue'),
    },

    {
      path: 'res/reservas/finalizar-reserva/:id',
      name: 'finalizar-reserva',
      props: (route:any) => {
        return !route.params.id ? { id: null } : { id: route.params.id }
      },
      component: () =>
        import( /* webpackChunkName: "finalizar-reserva" */ '../modules/reservar/pages/FinalizarReserva.vue'),
    },
    {
      path: '',
      redirect: { name: '404' }
    },
    ]

  },
  {
    path: '/:PathMatch(.*)*',
    redirect: { name: '404' }

  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
