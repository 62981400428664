import { Servicio } from "./servicio.model";
import { Sucursal } from "./sucursal.model";
import { Usuario } from "./usuario.model";

export class ServicioProfesionalResponse {
  public index!: number;
  public hora!: string;
  public servicio!: Servicio;
  public profesional!: Usuario;
  public sucursal!: Sucursal;


}