import { createStore } from 'vuex'
import establecimientoStore from './establecimiento'
import aforosStore from './aforos'
import sucursalesStore from './sucursales'
import disponibilidadStore from './disponibilidad'
import reservaStore from './reserva'
import generosStore from './genero'
import tiposIdentificacionStore from './tipo-documento'

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: 'des', isCompression: true, encryptionSecret: 's3cr3tPa$$w0rd@123', encryptionNamespace: 'aaaaa' });

export default createStore({
    modules: {
        establecimiento: establecimientoStore,
        aforos: aforosStore,
        sucursales: sucursalesStore,
        disponibilidad: disponibilidadStore,
        reserva: reservaStore,
        generos: generosStore,
        tiposIdentificacion: tiposIdentificacionStore
    },
    plugins: [createPersistedState({
        storage: {
            getItem: key => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: key => ls.remove(key)
        }
    })]
})