import { Utils } from '../utils/utils'
import axios from 'axios'

export class GeneroHelper {

    utils: Utils = new Utils();
    

    getGeneros = async () => {        
        const urlGeneros = this.utils.getGenero();
        return axios.get(`${urlGeneros}`)
    }

}


