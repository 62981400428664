import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { EstablecimientoHelper } from '../../helpers/establecimiento.helper'

const establecimientoHelper: EstablecimientoHelper = new EstablecimientoHelper();



class State {
    aforos: any[] = [];
    consultandoAforos: boolean = false;
    error: boolean = false;

}

const getters = <GetterTree<State, any>>{

    getterAforos(state) {
        return state.aforos
    },

    getterConsultandoEstablecimiento(state) {
        return state.consultandoAforos
    }
};

const mutations = <MutationTree<State>>{

    guardarAforos(state, aforos) {
        state.aforos = aforos
    },

    consultandoAforos(state, value) {
        state.consultandoAforos = value
    },

    errorAforos(state, value) {
        state.error = value
    }

};

const actions = <ActionTree<State, any>>{

    async consultarAforosPorEstablecimientoAction(state, idEstablecimiento) {


        state.commit('consultandoAforos', true);
        const aforos = await establecimientoHelper.getAforosPorEstablecimiento(idEstablecimiento);
        if (aforos) {
            state.commit('guardarAforos', aforos.data);
        } else {
            state.commit('errorAforos', true);
        }
        state.commit('consultandoAforos', false);


    }
};


const AfotorosModule = {
    namespaced: true,
    getters,
    state: new State(),
    mutations,
    actions
};

export default AfotorosModule;

