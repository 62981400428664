import { DisponibilidadResponse } from '@/models/disponibilidad-response.model';
import { ServicioProfesionalResponse } from '@/models/servicio-profesional-response.model';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { DisponibilidadlHelper } from '../../helpers/disponibilidad.helper'
import { DisponibilidadRequest } from '../../models/disponibilidad-request.model';

const disponibilidadlHelper: DisponibilidadlHelper = new DisponibilidadlHelper();

class State {
    disponibilidad: DisponibilidadResponse = new DisponibilidadResponse();
    seleccionarDisponibilidad: ServicioProfesionalResponse = new ServicioProfesionalResponse();
    disponibilidadRequest: DisponibilidadRequest = {};
    consultandoDisponibilidad: boolean = false;
    error: boolean = false

}

const getters = <GetterTree<State, any>>{

    getterDisponibilidad(state) {
        return state.disponibilidad
    },

    getterConsultandoDisponibilidad(state) {

        return state.consultandoDisponibilidad
    },

    getterDisponibilidadSeleccionada(state) {

        return state.seleccionarDisponibilidad
    },

    getterDisponibilidadRequest(state) {

        return state.disponibilidadRequest
    }
};

const mutations = <MutationTree<State>>{

    guardarDisponibilidadRequest(state, disponibilidadRequest) {
        state.disponibilidadRequest = disponibilidadRequest
    },

    guardarDisponibilidadResponse(state, disponibilidad) {
        state.disponibilidad = disponibilidad
    },

    consultandoDisponibilidad(state, value) {
        state.consultandoDisponibilidad = value
    },

    seleccionarDisponibilidad(state, value) {
        state.seleccionarDisponibilidad = value
    },


    errorDisponibilidad(state, value) {
        state.error = value
    },

};

const actions = <ActionTree<State, any>>{

    seleccionarDisponibilidad(state, disponibilidad: ServicioProfesionalResponse) {
        state.commit('seleccionarDisponibilidad', disponibilidad);      
    },

    async consultarDisponibilidadAction(state, disponibilidadRequest: DisponibilidadRequest) {


        state.commit('consultandoDisponibilidad', true);

        state.commit('guardarDisponibilidadRequest', disponibilidadRequest);
        const disponibilidad = await disponibilidadlHelper.getDisponibilidad(disponibilidadRequest);
        if (disponibilidad) {
            state.commit('guardarDisponibilidadResponse', disponibilidad.data);
            state.dispatch('reserva/actualizaProgresoreservaAction', 1, { root: true });
        } else {
            state.commit('errorDisponibilidad', true);
        }
        state.commit('consultandoDisponibilidad', false);


    }
};


const DisponibilidadModule = {
    namespaced: true,
    getters,
    state: new State(),
    mutations,
    actions
};

export default DisponibilidadModule;

